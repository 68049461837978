.menu:hover{
    background-color:#63d471;
    background-image: linear-gradient(315deg, #F98A14 0%, #FB4E02 74%);
    
    color: white;
    cursor: pointer;
        
}
 .menu{
     float: right;
     color: white;
        height:100%;
        padding:24px;
         font-size:17px;
        font-weight:bold;
    }

a{
    text-decoration: none;
    color: inherit;
    
}

.before{
    transition: 1s;
    padding: 0px;
    margin: 0px;
    position: fixed;
    background-color:rgba(255,255, 255, 0.9);
     box-shadow: 1px 1px 5px black ;
    z-index: 9;
    width: 100%;
    font-size: 16px;
    color: black
        
        
}

.after{
    transition: 1s;
    padding: 0px;
    margin: 0px;
    position: fixed;
    background-color: rgba(255, 255,255,1);
    box-shadow: 1px 1px 5px ;
    z-index: 1;
    width: 100%;
    font-size: 16px;
    color: white
        
}


.nav-wrapper ul{
    padding: 0px;
    margin: 0;
    display: inline-block;
    right: 0
        
        
}

.nav-wrapper ul li:hover{
    background-image: linear-gradient(315deg, #FB4E02 0%,  #F98A14 74%);
    color:white
}
.nav-wrapper ul li{
    display: inline-block;
    padding: 20px;
    margin: 0px;
    right: 0px;
    
        
}

.brand-logo{
   margin-right: 38%;     
}

.logo_img{
    width: 9%;
    margin-bottom:-15px;
        
}
.logo_img_small{
    width: 65%;
    margin-bottom:-25px;
    margin-top:-5px;
    margin-left:-28px;
        
}
.Quantity_air{
    border-color: white;
    color: white
}
.indexer{
    position: relative;
    background-color:#3B4954;
    z-index: 9999
}
.imager{
    background-color:#3B4954;
    z-index: 0;
    width: 100%
}
