@import url(//fonts.googleapis.com/css?family=Lato:300:400);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  margin:0;
}

h1 {
  font-family: 'Lato', sans-serif;
  font-weight:300;
  letter-spacing: 2px;
  font-size:48px;
}
p {
  font-family: 'Lato', sans-serif;
  letter-spacing: 1px;
  font-size:14px;
  color: #333333;
}

.header {
  position:relative;
  text-align:center;
  background: linear-gradient(60deg, rgba(84,58,183,1) 0%, rgba(0,172,193,1) 100%);
  color:white;
}
.logo {
  width:50px;
  fill:white;
  padding-right:15px;
  display:inline-block;
  vertical-align: middle;
}

.inner-header {
  height:65vh;
  width:100%;
  margin: 0;
  padding: 0;
}

.flex { /*Flexbox for containers*/
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.waves {
  position:relative;
  width: 100%;
  height:15vh;
  margin-bottom:-7px; /*Fix for safari gap*/
  min-height:100px;
  max-height:150px;
}

.content {
  position:relative;
  height:20vh;
  text-align:center;
  background-color: white;
}

/* Animation */

.parallax > use {
  -webkit-animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
          animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
}
.parallax > use:nth-child(1) {
  -webkit-animation-delay: -2s;
          animation-delay: -2s;
  -webkit-animation-duration: 7s;
          animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  -webkit-animation-delay: -3s;
          animation-delay: -3s;
  -webkit-animation-duration: 10s;
          animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  -webkit-animation-delay: -4s;
          animation-delay: -4s;
  -webkit-animation-duration: 13s;
          animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  -webkit-animation-delay: -5s;
          animation-delay: -5s;
  -webkit-animation-duration: 20s;
          animation-duration: 20s;
}
@-webkit-keyframes move-forever {
  0% {
   -webkit-transform: translate3d(-90px,0,0);
           transform: translate3d(-90px,0,0);
  }
  100% { 
    -webkit-transform: translate3d(85px,0,0); 
            transform: translate3d(85px,0,0);
  }
}
@keyframes move-forever {
  0% {
   -webkit-transform: translate3d(-90px,0,0);
           transform: translate3d(-90px,0,0);
  }
  100% { 
    -webkit-transform: translate3d(85px,0,0); 
            transform: translate3d(85px,0,0);
  }
}
/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height:40px;
    min-height:40px;
  }
  .content {
    height:30vh;
  }
  h1 {
    font-size:24px;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.menu:hover{
    background-color:#63d471;
    background-image: linear-gradient(315deg, #F14A0B 0%, #ED7215 74%);
    
    color: white;
    cursor: pointer;
        
}
 .menu{
     float: right;
     color: black;
        height:100%;
        padding:24px;
         font-size:17px;
        font-weight:bold;

    }

a{
    text-decoration: none;
    color: inherit
}

.before_{
    transition: 1s;
    padding: 0px;
    margin: 0px;
    position: fixed;
    background-color: rgba(255, 255,255,0.9);
     box-shadow: 0.3px 0.3px 2px gray ;
    z-index: 99;
    width: 100%;
    font-size: 20px;
    color: black
        
        
}

.after_{
    transition: 1s;
    padding: 0px;
    margin: 0px;
    position: fixed;
    background-color: rgba(255, 255,255,0.3);
    box-shadow: 1px 1px 5px ;
    z-index: 1;
    width: 100%;
    font-size: 16px;
    color: white
        
}


.nav-wrapper ul{
    padding: 0px;
    margin: 0;
    display: inline-block;
    right: 0
        
        
}

.nav-wrapper ul li:hover{
    background-image:linear-gradient(315deg, #eeeeee 0%,  #F5F5F5 74%);

    color:gray;
    border-bottom: solid;
    border-bottom-color: grey;
    
}
.nav-wrapper ul li{
    display: inline-block;
    padding: 20px;
    margin: 0px;
    right: 0px;
    
        
}

.brand-logo{
   margin-right: 43%;     
}

.logo_img_{
    width: 7%;
    margin-bottom:-30px;
        
}
.logo_img_small_{
    width: 28%;
    margin-bottom:-15px;
        
}
.particle{
position: relative;
  width: 100%;
  height: 100%;
  background-image:url(/static/media/back1.0aa97fcb.jpg);
    background-position: bottom;
      background-repeat: no-repeat;
      background-color: black;
      background-size: cover;

}.scientific{
position: relative;
  width: 100%;
  height: 100%;
  background-image:url(/static/media/scientific.d1397746.jpg);
    background-position: bottom;
      background-repeat: no-repeat;
      background-color: black;
      background-size: cover;

}.web{
position: relative;
  width: 100%;
  height: 100%;
  background-image:url(/static/media/back1.0aa97fcb.jpg);
    background-position: bottom;
      background-repeat: no-repeat;
      background-color: black;
      background-size: cover;

}
.port{
position: relative;
  width: 100%;
  height: 100%;
  background-image:url(/static/media/back3.79dcf92f.jpg);
    background-position: bottom;
      background-repeat: no-repeat;
      background-color: black;
      background-size: cover;

}

.contact{
position: relative;
  width: 100%;
  height: 100%;
  background-image:url(/static/media/contact2.1d9e45cf.jpg);
    background-position: bottom;
      background-repeat: no-repeat;
      background-color: black;
      background-size: cover;

}.cv{
position: relative;
  width: 100%;
  height: 100%;
  background-image:url(/static/media/back2.c39ebe34.jpg);
    background-position: bottom;
      background-repeat: no-repeat;
      background-color: black;
      background-size: cover;

}
.menu:hover{
    background-color:#63d471;
    background-image: linear-gradient(315deg, #F98A14 0%, #FB4E02 74%);
    
    color: white;
    cursor: pointer;
        
}
 .menu{
     float: right;
     color: white;
        height:100%;
        padding:24px;
         font-size:17px;
        font-weight:bold;
    }

a{
    text-decoration: none;
    color: inherit;
    
}

.before{
    transition: 1s;
    padding: 0px;
    margin: 0px;
    position: fixed;
    background-color:rgba(255,255, 255, 0.9);
     box-shadow: 1px 1px 5px black ;
    z-index: 9;
    width: 100%;
    font-size: 16px;
    color: black
        
        
}

.after{
    transition: 1s;
    padding: 0px;
    margin: 0px;
    position: fixed;
    background-color: rgba(255, 255,255,1);
    box-shadow: 1px 1px 5px ;
    z-index: 1;
    width: 100%;
    font-size: 16px;
    color: white
        
}


.nav-wrapper ul{
    padding: 0px;
    margin: 0;
    display: inline-block;
    right: 0
        
        
}

.nav-wrapper ul li:hover{
    background-image: linear-gradient(315deg, #FB4E02 0%,  #F98A14 74%);
    color:white
}
.nav-wrapper ul li{
    display: inline-block;
    padding: 20px;
    margin: 0px;
    right: 0px;
    
        
}

.brand-logo{
   margin-right: 38%;     
}

.logo_img{
    width: 9%;
    margin-bottom:-15px;
        
}
.logo_img_small{
    width: 65%;
    margin-bottom:-25px;
    margin-top:-5px;
    margin-left:-28px;
        
}
.Quantity_air{
    border-color: white;
    color: white
}
.indexer{
    position: relative;
    background-color:#3B4954;
    z-index: 9999
}
.imager{
    background-color:#3B4954;
    z-index: 0;
    width: 100%
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

    
.menu:hover{
    background-color:#63d471;
    background-image: linear-gradient(315deg, #F14A0B 0%, #ED7215 74%);
    
    color: white;
    cursor: pointer;
        
}
 .menu{
     float: right;
     color: black;
        height:100%;
        padding:24px;
         font-size:17px;
        font-weight:bold;
    }

a{
    text-decoration: none;
    color: inherit
}

.before{
    transition: 1s;
    padding: 0px;
    margin: 0px;
    position: fixed;
    background-color: rgba(255, 255,255,1);
     box-shadow: 1px 1px 5px black ;
    z-index: 1;
    width: 100%;
    font-size: 20px;
    color: black
        
        
}

.after{
    transition: 1s;
    padding: 0px;
    margin: 0px;
    position: fixed;
    background-color: rgba(255, 255,255,1);
    box-shadow: 1px 1px 5px ;
    z-index: 1;
    width: 100%;
    font-size: 16px;
    color: white
        
}


.nav-wrapper ul{
    padding: 0px;
    margin: 0;
    display: inline-block;
    right: 0
        
        
}

.nav-wrapper ul li:hover{
      background-image: linear-gradient(315deg, #2FA71B 0%, #0F6300 74%);
    color:white
}
.nav-wrapper ul li{
    display: inline-block;
    padding: 20px;
    margin: 0px;
    right: 0px;
    
        
}

.brand-logo{
   margin-right: 43%;     
}

.logo_img{
    width: 6%;
    margin-bottom:-15px;
        
}
.logo_img_small{
    width: 33%;
    margin-bottom:-15px;
        
}
